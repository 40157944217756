body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* disable the "bouncy" scroll */
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    display: flex;
}

.sentry-error-embed .form-submit .btn {
    background-color: #0d1a38 !important;
    border-color: #0d1a38 !important;
}

.sentry-error-embed * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

/* supports printing V2 lab slips */
@page {
    size: letter landscape;
    margin: 8px;
}

tr, td, th, img {
    page-break-inside: avoid !important;
}

@media print {
    html, body {
        height: auto;
    }
    * {
        overflow-y: visible !important;
        max-height: unset !important;
        height: auto;
    }
    
    /* Hide the main page to allow printing lab slips without the nav menus */
    html > :not(body), body > :not(#printable-slip, #root), #root.lab-slip-active, #root.lab-slip-active * {
        display: none !important;
    }
}

/* This fixes a styling offset bug in the gantt chart library.
See https://github.com/MaTeMaTuK/gantt-task-react/issues/204 for details
*/
._3KcaM {
    transform: translateY(4px);
}
